 
// File: app/(pages)/layout.jsx
'use client';
import AOS from 'aos';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'aos/dist/aos.css';
import './style.scss';
import './icons.scss';

import 'aos';
import 'swiper';
import Navbar from './components/navbar';
import React, { useEffect } from 'react';
import Footer from './components/footer';

 

 

export default function RootLayout({ children }) {

  useEffect(() => {
    // Animation on Scroll (Plugin)
    AOS.init();

    // Topnav Active Menu
    const initTopnav = () => {
      const pageUrl = window.location.href.split(/[?#]/)[0];
      const navbarLinks = Array.from(document.querySelectorAll('#navbar .navbar-nav a'));

      navbarLinks.forEach((link) => {
        if (link.href === pageUrl) {
          link.classList.add('active');

          const parentMenu = link.parentElement?.parentElement?.parentElement;
          if (parentMenu?.classList.contains('nav-item')) {
            const dropdownElement = parentMenu.querySelector('[data-fc-type="dropdown"]');
            dropdownElement?.classList.add('active');
          }

          const parentParentMenu = link.parentElement?.parentElement?.parentElement?.parentElement?.parentElement;
          if (parentParentMenu?.classList.contains('nav-item')) {
            const dropdownElement = parentParentMenu.querySelector('[data-fc-type="dropdown"]');
            dropdownElement?.classList.add('active');
          }
        }
      });
    };

    // Mobile Menu Active
    const initMobileNav = () => {
      const pageUrl = window.location.href.split(/[?#]/)[0];
      const navbarLinks = Array.from(document.querySelectorAll('#mobileMenu .navbar-nav a'));

      navbarLinks.forEach((link) => {
        if (link.href === pageUrl) {
          link.classList.add('active');
          const parentMenu = link.parentElement?.parentElement?.parentElement;
          const parentCollapse = link.parentElement?.parentElement;
          if (parentMenu?.classList.contains('nav-item')) {
            const collapseElement = parentMenu.querySelector('[data-fc-type="collapse"]');
            collapseElement?.classList.add('active');
            if (collapseElement) {
              const collapse = Swiper.getInstanceOrCreate(collapseElement);
              collapse.show();
              if (parentCollapse) {
                parentCollapse.style.height = null;
              }
            }
          }
        }
      });
    };

    // Topbar Sticky
    const initStickyNav = () => {
      const windowScroll = () => {
        const navbar = document.getElementById("navbar");
        if (navbar) {
          if (document.body.scrollTop >= 75 || document.documentElement.scrollTop >= 75) {
            navbar.classList.add("nav-sticky");
          } else {
            navbar.classList.remove("nav-sticky");
          }
        }
      };
      window.addEventListener("scroll", (ev) => {
        ev.preventDefault();
        windowScroll();
      });
    };

    // Back To Top
    const initBacktoTop = () => {
      const mybutton = document.querySelector('[data-toggle="back-to-top"]');

      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 72) {
          mybutton?.classList.add('flex');
          mybutton?.classList.remove('hidden');
        } else {
          mybutton?.classList.remove('flex');
          mybutton?.classList.add('hidden');
        }
      });

      mybutton?.addEventListener('click', (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    };

    // Swiper ( One card ) (Plugin)
    const initswiperOne = () => {
      new Swiper("#swiper_one", {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        rewind: true,
        navigation: {
          nextEl: ".button-next",
          prevEl: ".button-prev",
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
        },
      });
    };

    // Swiper ( Two card ) (Plugin)
    const initswiperTwo = () => {
      new Swiper("#swiper_two", {
        slidesPerView: 1,
        loop: true,
        autoHeight: true,
        spaceBetween: 30,
        navigation: {
          nextEl: ".button-next",
          prevEl: ".button-prev",
        },
        breakpoints: {
          576: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
        },
      });
    };

    // Text Animation
    const initTypewrite = () => {
      class TxtType {
        constructor(el, toRotate, period) {
          this.toRotate = toRotate;
          this.el = el;
          this.loopNum = 0;
          this.period = parseInt(period, 10) || 2000;
          this.txt = '';
          this.tick();
          this.isDeleting = false;
        }

        tick() {
          const i = this.loopNum % this.toRotate.length;
          const fullTxt = this.toRotate[i];
          if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
          } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
          }
          this.el.innerHTML = '<span className="wrap">' + this.txt + '</span>';
          let delta = 200 - Math.random() * 100;
          if (this.isDeleting) {
            delta /= 2;
          }
          if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.period;
            this.isDeleting = true;
          } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
          }
          setTimeout(() => {
            this.tick();
          }, delta);
        }
      }

      const elements = document.getElementsByClassName('typewrite');
      for (let i = 0; i < elements.length; i++) {
        const toRotate = elements[i].getAttribute('data-type');
        const period = elements[i].getAttribute('data-period');
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
      }
    };

    initMobileNav();
    initTopnav();
    initStickyNav();
    initBacktoTop();
    initswiperOne();
    initswiperTwo();
    window.onload = initTypewrite;
  }, []);
 
  return (
    <> 
    <div id="public-pages"  className={' public-pages ' } > 
     <Navbar></Navbar>  
   
    
 
    
          {children}

          <Footer></Footer>
     </div>
   </>
       
  );
}
